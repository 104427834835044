import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ReactPlayer from 'react-player'

import { getVideo, viewedVideo, eventVideo } from '../../actions/watchActions';
import "./styles_play.css";

const videoConstraints = {
	width: 320,
	height: 320,
	facingMode: "user"
};

export default class Play extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isPlaying: false,
			ended: false,
			canPlay: true,
			processing: false,
			urlVideo: null,
			isLoading: true,
			videoAvailable: false,
			loadedVideo: 0,
			iframeUrl: null,
			current_video: 0,
			infoData: {},
			videos: [],
			finished: false,
		}
		// console.log(props.campaign_id)
		// if(){
		this.campaign_id = props.campaign_id;
		// }

		this._onEnded = this._onEnded.bind(this);
	}





	cleanUrl(url, show_iframe = false) {

		let post = { "campaign_id": this.campaign_id, "type_page": "Video" };
		let tags = url.match(/({{[a-z_0-9]+}})/gi);
		let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(', '').replaceAll(')', '');
		let params = new URLSearchParams(query_string);

		for (var pair of params.entries()) {
			post[pair[0]] = pair[1];
		}

		tags.map((val) => {
			url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
		});
		const redirect_url = new URL(url)

		let searchParams = new URLSearchParams(redirect_url.search);


		// console.log(url);
		this.setState({
			iframeUrl: url,
		});


		for (var pair of searchParams.entries()) {
			post[pair[0]] = pair[1];
		}
		eventVideo(post).then(() => {
			// window.location.href = url;
			// console.log(url)
			// console.log("Caiu aqui")
			setTimeout(() => {
				window.location.href = url;
			}, 1500);
		});

	}

	async componentDidMount() {
		let response = await getVideo(this.campaign_id);
		this.state.videos = response.data;

		const element = response.data;
		if (element.viewed_at == null) {
			this.campaign_id = element.campaign_id;
			this.setState({
				infoData: element,
				videoAvailable: true,
				isLoading: false,
				processing: false
			})
		}
		if (this.state.videoAvailable) {
			//this.loadVideo();
			this.setState({
				urlVideo: element.campaign_video,
				loadedVideo: 100
			})
		} else {
			this.setState({
				videoAvailable: true,
				infoData: { viewed_at: true },
				isLoading: false,
				processing: false
			})
		}
		gtag('event', 'page_view', {
			'page_title': 'Vídeo ' + response.data['name'],
			'page_location': window.location.href
		});



	}

	loadVideo() {
		this.screenshots = [];
		this.setState({ videoAvailable: true });
		var req = new XMLHttpRequest();
		req.open('GET', this.state.infoData.campaign_video, true);
		req.responseType = 'blob';

		req.onprogress = function (e) {
			let percent = Math.round((e.loaded * 100) / e.total);
			this.setState({ loadedVideo: percent });
		}.bind(this)

		req.onload = function (e) {
			if (req.readyState === 4) {
				if (req.status === 200) {
					var vid = window.URL.createObjectURL(req.response); // IE10+
					this.setState({ urlVideo: vid });
				}
			}
		}.bind(this)
		req.onerror = function () {
			// Error
		}

		req.send();
	}



	_onEnded() {
		let { infoData } = this.state;
		// console.log(infoData['qtdViews']);

		//let url_redirect = 'https://transit.nicequest.com/transit/participation?tp=co_0&c=ok&ticket={{ticket}}';
		let url_redirect = this.state.infoData.redirect_complete;
		if (infoData['qtdViews'] > 450) {
			url_redirect = this.state.infoData.redirect_full;
		}
		if (this.state.infoData.max_views != null && this.state.infoData.redirect_full && this.state.infoData.total_views > this.state.infoData.max_views) {
			url_redirect = this.state.infoData.redirect_full;
		}
		this.cleanUrl(url_redirect, true);
		this.setState({
			finished: true
		});
		gtag('event', 'page_view', {
			'page_title': 'Vídeo Vizualizado ' + response.data['name'],
			'page_location': window.location.href + '?finished=true'
		});

	}
	onFinished() {

	}

	_startVideo() {
		this.setState({
			isPlaying: true
		})
	}
	_onUserMedia() {
		this.setState({
			canPlay: true,

		})
	}

	render() {
		const { finished, infoData, isPlaying, ended, canPlay, processing, urlVideo, videoAvailable, isLoading, loadedVideo, iframeUrl } = this.state;


		console.log(infoData['qtdViews']);


		return (
			isLoading ?
				<div>
					<p className="text-center py-4">
						<span className="fa fa-refresh fa-spin fa-4x"></span>
					</p>
				</div> :
				finished ?
					<div>
						<nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
							<div className="container-fluid">
								<div className="navbar-translate mb-2 mt-2">
									<img src="/assets/img/logo.png" height="55" alt="Brandful" />
								</div>

								<div className="collapse navbar-collapse hidden-print">
									<ul className="navbar-nav ml-auto">
										<li className="nav-item">
											<a className="nav-link" target="_blank" href="https://brandful.com.br/privacy_stat.html">Termos de Uso</a>

											{/* <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">Termos de Uso</a>
                                 */}
										</li>

									</ul>
								</div>
							</div>
						</nav>
						<ol className="breadcrumb py-4">
							<li className="breadcrumb-item font-weight-bold">Painelista | Bem Vindo</li>
						</ol>
						<div className="container-fluid">
							<h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>

							<p>Assista ao vídeo abaixo para participar da pesquisa.</p>
							<ul className="list-unstyled text-muted font-weight-bold">
								<li>Passo 1: Clique sobre o botão iniciar abaixo para começar a pesquisa.</li>
								<li>Passo 2: Assista o comercial até o fim.</li>
								<li>Passo 3: Aguarde  a mensagem de sucesso.</li>
							</ul>

						</div>
						<p className="text-center py-4">Obrigado por participar.</p>
					</div>
					:
					<div>
						<nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
							<div className="container-fluid">
								<div className="navbar-translate mb-2 mt-2">
									<img src="/assets/img/logo.png" height="55" alt="Brandful" />
								</div>

								<div className="collapse navbar-collapse hidden-print">
									<ul className="navbar-nav ml-auto">
										<li className="nav-item">
											<a className="nav-link" target="_blank" href="https://brandful.com.br/privacy_stat.html">Termos de Uso</a>

											{/* <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">Termos de Uso</a>
                                 */}
										</li>

									</ul>
								</div>
							</div>
						</nav>

						<ol className="breadcrumb py-4">
							<li className="breadcrumb-item font-weight-bold">Painelista | Bem Vindo</li>
						</ol>
						<div className="container-fluid">
							<h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>

							<p>Assista ao vídeo abaixo para participar da pesquisa.</p>
							<ul className="list-unstyled text-muted font-weight-bold">
								<li>Passo 1: Clique sobre o botão iniciar abaixo para começar a pesquisa.</li>
								<li>Passo 2: Assista o comercial até o fim.</li>
								<li>Passo 3: Aguarde  a mensagem de sucesso.</li>
							</ul>

						</div>
						{videoAvailable && infoData['viewed_at'] === null ?
							<div>
								<div className="container-fluid" style={{ paddingBottom: '60px' }}>

									{processing ? <div className="alert alert-warning text-center"><p className="pt-2">Processando vídeo</p><p><i className="fa fa-refresh fa-spin"></i> {loadedVideo}%</p></div> : null}
									{ended ? <div className="alert alert-success text-center"><i className="fa fa-check-circle-o"></i> Vídeo visualizado com sucesso.</div> :
										(processing === false ? <div className="player-wrapper">
											<ReactPlayer className='react-player'
												playing={isPlaying}
												url={urlVideo}
												controls={false}
												playsinline={true}
												width='100%'
												onEnded={this._onEnded}
												onPlay={this._onPlay} />
											{isPlaying ? null :
												canPlay && urlVideo ?
													<button className="btn btn-play-video" onClick={() => { this._startVideo() }}><i className="fa fa-play-circle-o"></i></button> :
													<div className="loading-video">
														<span className="fa fa-refresh fa-spin"></span>
														<span className="percent">{loadedVideo}%</span>
													</div>
											}
										</div> : null)
									}
								</div>

							</div>
							:
							<div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '70vh' }}>
								{videoAvailable ?
									<div className="alert alert-info text-center">Você já participou desse estudo</div>
									: <div className="alert alert-warning text-center">Vídeo indisponível no momento</div>
								}

							</div>
						}
						<Footer />
						{iframeUrl ? <iframe src={iframeUrl} width="0" height="0" className='d-none'></iframe> : null}
					</div>
		)
	}
}
